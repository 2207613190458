import Layout from '@/components/Layout';
import { RefreshIcon } from '@heroicons/react/outline';
import { PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useEffect } from 'react';

const Home: FC<PageProps> = ({ navigate }) => {
  useEffect(() => {
    const timeout = setTimeout(() => navigate('/ro/', { replace: true }), 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Layout hideHeader minimalFooter>
      <div className="w-screen h-screen flex items-center justify-center">
        <RefreshIcon className="text-primary w-10 h-10 animate animate-spin" />
      </div>
    </Layout>
  );
};

export default Home;
